exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-edm-diesinker-js": () => import("./../../../src/pages/edm/diesinker.js" /* webpackChunkName: "component---src-pages-edm-diesinker-js" */),
  "component---src-pages-edm-highspeed-js": () => import("./../../../src/pages/edm/highspeed.js" /* webpackChunkName: "component---src-pages-edm-highspeed-js" */),
  "component---src-pages-edm-js": () => import("./../../../src/pages/edm.js" /* webpackChunkName: "component---src-pages-edm-js" */),
  "component---src-pages-edm-superdrill-js": () => import("./../../../src/pages/edm/superdrill.js" /* webpackChunkName: "component---src-pages-edm-superdrill-js" */),
  "component---src-pages-edm-wirecut-js": () => import("./../../../src/pages/edm/wirecut.js" /* webpackChunkName: "component---src-pages-edm-wirecut-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lathes-js": () => import("./../../../src/pages/lathes.js" /* webpackChunkName: "component---src-pages-lathes-js" */),
  "component---src-pages-lathes-machining-js": () => import("./../../../src/pages/lathes/machining.js" /* webpackChunkName: "component---src-pages-lathes-machining-js" */),
  "component---src-pages-lathes-speedmachine-js": () => import("./../../../src/pages/lathes/speedmachine.js" /* webpackChunkName: "component---src-pages-lathes-speedmachine-js" */),
  "component---src-templates-edm-template-js": () => import("./../../../src/templates/edm-template.js" /* webpackChunkName: "component---src-templates-edm-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

